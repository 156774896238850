.section-contact {
  &__title {
    border-bottom: 1px solid $border-color;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 16px;
    margin-bottom: 26px;
  }
}
.contact-map {
  height: 225px;
  margin-bottom: 50px;
}
.contact-info {
  font-family: $open-sans;
  &__title {
    font-size: 16px;
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__detail {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }
  li {
    padding-left: 24px;
    position: relative;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}
