.nav-pills {
  justify-content: center;
  margin-bottom: 30px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .nav-link {
    color: #333;
    border: 1px solid #333;
    font-size: 14px;
    padding: 2px 10px 5px;
    border-radius: 20px;
    min-width: 120px;
    text-align: center;
    &:hover {
      color: #333;
    }
    &.active {
      background-color: #333;
      color: #fff;
    }
  }

  @media (max-width: 991px) {
    display: block;
    white-space: nowrap;
    overflow: auto;
    text-align: center;

    .nav-item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.news-category {
  display: flex;
  border-bottom: 1px solid $border-color;
  margin-bottom: 30px;
  justify-content: space-between;
  a {
    display: block;
    font-size: 14px;
    color: #333;
    padding: 15px 0;
    &:hover {
      color: #333;
      text-decoration: none;
    }
    &.active {
      color: $green;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        border-bottom: 3px solid $green;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    a.active {
      padding-left: 20px;
      &::after {
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 3px;
        border: 0;
        background-color: $green;
      }
    }
  }
}

.nav-info {
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  margin-bottom: 16px;
  .nav-link {
    color: #333;
    padding: 14px 0;
    &:hover {
      color: #333;
    }
    &.active {
      color: $green;
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        width: 100%;
        border-bottom: 3px solid $green;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: -2px;
      }
    }
  }
}
