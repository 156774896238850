.sticky-btn {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 20px;
  list-style: none;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    background-color: $red;
    color: $red;
    border: 2px solid darken($red, 10%);
    box-shadow: 0 2px 10px rgba(#000, 0.15);
    border-radius: 50%;
    padding: 6px;

    svg,
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }

    &--zalo {
      padding: 0;
      border-color: darken(#0180c7, 10%);

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--phone {
      background-color: $green;
      border-color: darken($green, 10%);
    }
  }

  @media (max-width: 991px) {
    transition: 0.3s;
    top: auto;
    // bottom: 60px;
    bottom: 10px;
    right: 10px;
    padding-right: 0;
    transform: translate(0, 0);

    &__item {
      transition: 0.3s;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      border-width: 2px;
    }

    &.active {
      bottom: 10px;
    }

    &.active &__item {
      &:not(:first-child) {
        margin-top: -40px;
      }
    }
  }
}

.sticky-toggle {
  display: none;

  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
    display: flex;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: #eee;
    border-radius: 50%;
    border: 0;
    outline: 2px #fff;
    box-shadow: 0 1px 3px rgba(#000, 0.6);
    overflow: hidden;
    position: fixed;
    z-index: 1030;
    right: 10px;
    bottom: 10px;
    transition: 0.3s;
    cursor: pointer;

    span {
      display: block;
      width: 20px;
      height: 2px;
      background-color: #333;
      margin: 2px 0;
      transition: 0.3s;

      &:nth-child(1) {
        transform: translateY(6px) rotateZ(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
        transform: translateX(-5px);
      }
      &:nth-child(3) {
        transform: translateY(-6px) rotateZ(-45deg);
      }
    }

    &.active span {
      &:nth-child(1) {
        transform: translateY(0) rotateZ(0);
      }
      &:nth-child(2) {
        opacity: 1;
        transform: translateX(0);
      }
      &:nth-child(3) {
        transform: translateY(0) rotateZ(0);
      }
    }
  }
}
