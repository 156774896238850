.hover-zoom {
  @include img-zoom;
}
.text-green {
  &,
  &:hover {
    color: $green;
  }
}
.text-red {
  &,
  &:hover {
    color: $red;
  }
}
// spacing
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

// spacing for last child
.m-first-0:first-child,
.m-last-0:last-child {
  margin: 0 !important;
}
.p-first-0:first-child,
.p-last-0:last-child {
  padding: 0 !important;
}

// text
.link-unstyled {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

// add more font weight
$font-weight-list: 300 400 500 600 700 800 900;
@each $item in $font-weight-list {
  .font-weight-#{$item} {
    font-weight: #{$item} !important;
  }
}

// add more font-size
$font-size-list: 10 12 13 14 15 16 18 20 22 24 25 26 28 30;
@each $item in $font-size-list {
  .text-#{$item} {
    font-size: #{$item}px !important;
  }
}

// add limit line for text
%max-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
}
@each $item in 1 2 3 4 5 6 7 8 9 10 {
  .max-line-#{$item} {
    @extend %max-line;
    -webkit-line-clamp: #{$item};
    max-height: #{$item * 1.5}em;
  }
}

// utilities
.over-hidden {
  overflow: hidden;
}

// iwrap
%iwrap {
  display: block;
  position: relative;
  &::before {
    content: "";
    display: block;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50%;
  }
}
$iwrap-list: (
  100: 100%,
  50: 50%,
  66: 66.666%,
  56: 56.666%,
  60: 60%,
  160: 160%
);
.iwrap {
  @extend %iwrap;
}
@each $key, $val in $iwrap-list {
  .iwrap-#{$key} {
    @extend %iwrap;
    &::before {
      padding-bottom: #{$val};
    }
  }
}
.iwrap-contain {
  img {
    object-fit: contain;
  }
}
.iwrap-maxwidth {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}

.gutter-20 {
  margin: 0 -10px;
  & > [class^="col"] {
    padding: 0 10px;
  }
}
@media (min-width: 1200px) {
  .gutter-xl-50 {
    margin: 0 -25px;
    & > [class^="col"] {
      padding: 0 25px;
    }
  }
}

// add column 20% (5 items in a row)
$grid-breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}
@each $key, $val in $grid-breakpoints {
  .col-#{$key}-20 {
    padding: 0 15px;
  }
  @media (min-width: #{$val}) {
    .col-#{$key}-20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.page {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  &__content {
    flex-grow: 1;
  }
}

.button {
  display: inline-block;
  @include btn-fill();
  font-size: 16px;
  line-height: 38px;
  padding: 0 28px;
  border: 1px solid #999;
  color: #333;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    color: $white;
    text-decoration: none;
    border-color: #333;
  }

  &::before {
    background-color: #333;
  }

  &--sm {
    font-size: 14px;
    line-height: 28px;
  }
}

.customer {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__right {
    position: relative;
  }

  &__body {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow: auto;
    border: 1px solid $green-darker;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__item {
    color: #333;
    font-size: 14px;
    font-family: $avo-bold;

    &:hover {
      color: #333;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__frame {
    display: block;
    width: 86px;
    margin-right: 20px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 56.25%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 100%;

    &__body {
      position: static;
      max-height: 300px;
    }
  }
}

.support-text {
  background-color: #108518;
  color: $white;
  font-family: $avo;
  font-size: 25px;
  text-align: center;
  line-height: 30px;
  padding: 33px 20px;

  a {
    font-family: $avo-bold;
    color: $white;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.filter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;
  margin-bottom: 30px;

  &__btn {
    height: 35px;
    font-weight: 700;
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
