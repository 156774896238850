.post {
  margin-bottom: 40px;
  &-title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &-time {
    margin-bottom: 10px;
  }
  &-sapo {
    font-size: 14px;
    text-align: justify;
  }
  &-content {
    text-align: justify;
  }
}
