.pagination {
  margin-bottom: 0;
}
.page-item {
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
}
.page-link {
  font-size: 16px;
  color: #333;
  border: 1px solid $border !important;
  background: 0;
  padding: 8px 15px;
  &:hover {
    color: $green;
  }
  &:focus {
    box-shadow: unset !important;
  }
}
.page-item.disabled .page-link {
  background: 0;
  border-color: #666;
}
.page-item.active .page-link {
  background-color: $green;
  border-color: $green;
}
