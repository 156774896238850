.banner {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  min-height: 200px;
  padding: 50px 0;
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  &__title {
    text-align: center;
    font-family: $avo-bold;
    font-size: 25px;
    text-transform: uppercase;
    color: #333;
  }
}
