.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    margin: 4px 0 8px;
    position: relative;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.3s;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    &.active span {
      transform: rotate(90deg);
    }
  }

  &-sub {
    display: none;
  }

  &-sub &-link {
    padding-left: 40px;
  }

  &-sub-2 &-link {
    padding-left: 60px;
  }

  &-sub-3 &-link {
    padding-left: 80px;
  }

  &-item:hover > &-link {
    background-color: rgba($green, 0.05);
  }

  &-mega-bg {
    display: none;
  }

  &-root > &-item > &-link {
    text-transform: uppercase;
    font-weight: 600;
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    // menu root
    &-root {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }

    &-root > &-item {
      margin: 0;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    &-root > &-item > &-link {
      text-align: center;
      font-size: 15px;
      line-height: 50px;
      padding: 0;

      &.active {
        color: $green;
        font-family: $avo-bold;
      }
    }

    &-root > &-item.menu-item-group > &-link {
      padding-right: 0;
    }

    &-root > &-item:hover > &-link {
      background: 0;
      color: $green;
    }

    &-root > &-item-group > &-toggle {
      display: none;
    }

    &-root &-sub {
      min-width: 160px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: -4px;
      left: calc(100% + 4px);
      border-radius: 4px;
      transform-origin: 0 4px;
      background-color: $white;

      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
      }

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-root &-sub &-link {
      padding-left: 20px;
    }

    &-root &-item-group > &-link {
      padding-right: 40px;
    }

    &-root &-item-group:hover > &-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }

    &-root > &-item-group > &-sub {
      transform-origin: 0 0;
      top: calc(100% + 4px);
      left: 0;

      &::before {
        width: 100%;
        height: 8px;
        right: auto;
        left: 0;
        top: auto;
        bottom: 100%;
      }
    }

    // menu down
    &-down > &-item {
      position: static;
      margin: 0;
    }

    &-down &-mega {
      display: none;
      width: 863px;
      height: 100%;
      min-height: 470px;
      align-items: flex-start;
      padding: 20px;
      background-color: $white;
      border-left: 1px solid #e6e6e6;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 100%;
    }

    &-down > &-item-group:hover > &-mega {
      display: block;
    }

    &-down &-toggle {
      display: none;
    }

    &-down > &-item-group > &-link {
      padding-right: 30px;
      position: relative;

      &::after {
        content: "\f105";
        font-family: "fontawesome";
        position: absolute;
        top: 0;
        right: 10px;
      }
    }

    &-down &-sub {
      display: block;
    }

    &-down &-sub &-item:hover > &-link {
      background-color: transparent;
    }

    &-down &-sub &-item {
      margin: 0;
      padding: 2px 4px;
    }

    &-down &-sub &-link {
      line-height: 1.5;
      padding: 0;
      white-space: normal;
    }

    &-down &-sub &-link:hover {
      color: $green;
    }

    &-down &-sub-1 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      width: 100%;
    }

    &-down &-sub-1 > &-item {
      margin-bottom: 4px;
    }

    &-down &-sub-1 > &-item > &-link {
      font-weight: 700;
    }

    &-down &-sub-2 {
      margin-top: 6px;
    }
  }
}

@keyframes menu-float-show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.h-menu {
  &__btn {
    display: none;
  }

  @media (min-width: 1200px) {
    &__btn {
      display: inline-block;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      z-index: 1;
      line-height: 55px;
      padding: 0 20px 0 10px;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 20px;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }

    &__float {
      display: none;
      width: 246px;
      background-color: $white;
      position: absolute;
      z-index: 200;
      top: 100%;
      left: 0;
    }

    &__backdrop {
      display: none;
      width: 100vw;
      height: 200vh;
      position: absolute;
      top: 100%;
      left: 50%;
      right: 0;
      z-index: 100;
      transform: translateX(-50%);
      background-color: rgba(#000, 0.5);
    }

    &__content:hover &__float {
      display: block;
    }

    &__content:hover ~ &__backdrop {
      display: block;
    }
  }
}

body.is-home .h-menu__float {
  display: block;
}

body.is-home .h-menu__backdrop {
  display: none !important;
}
