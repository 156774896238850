.field {
  display: block;
  position: relative;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &__title {
    display: flex;
    align-items: flex-end;
    height: 40%;
    width: 100%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 16px;
    font-family: $avo-bold;
    font-size: 14px;
    font-weight: bold;
    color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__frame {
    display: block;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 66.66%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.project {
  &__info {
    text-align: center;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.project-2 {
  box-shadow: 0 4px 16px rgba(#000, 0.3);
  &__iwrap {
    align-self: stretch;
    width: 445px;
  }
  &__body {
    height: 100%;
    padding: 20px;
  }
  &__title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;
    &::before {
      content: "";
      width: 43px;
      border-bottom: 2px solid $green;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__info {
    margin-bottom: 15px;
  }
  &__tags {
    a {
      display: inline-block;
      font-size: 11px;
      padding: 0 10px;
      background-color: #243625;
      color: $white;
      line-height: 22px;
      border-radius: 12px;
      padding-bottom: 2px;
      margin-right: 15px;
      margin-bottom: 8px;
      transition: 0.3s;
      &:hover {
        color: $white;
        text-decoration: none;
        background-color: $green;
      }
    }
  }
  &__link {
    &,
    &:hover {
      color: $green;
      text-decoration: underline;
    }
  }
  @media (max-width: 991px) {
    &__iwrap {
      width: 250px;
    }
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    &__iwrap {
      width: 100%;
    }
  }
}

.contractor {
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}

.contractor-2 {
  &__iwrap {
    width: 250px;
    margin-right: 30px;
  }
  &__title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
  }
  &__desc {
    margin-bottom: 30px;
  }
  &__tags {
    a {
      display: inline-block;
      font-size: 11px;
      padding: 0 10px;
      background-color: #243625;
      color: $white;
      line-height: 22px;
      border-radius: 12px;
      padding-bottom: 2px;
      margin-right: 15px;
      margin-bottom: 8px;
      transition: 0.3s;
      &:hover {
        color: $white;
        text-decoration: none;
        background-color: $green;
      }
    }
  }
  &__btn {
    font-size: 14px;
    padding: 0 17px 2px;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    &__iwrap {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.anchor {
  padding: 30px 0 40px;
  position: relative;
  z-index: 1;
  text-align: center;
  color: $white;
  background: url("../images/anchor-bg.jpg") center/cover no-repeat;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($green-darker, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
  &__title {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 12px;
  }
  &__desc {
    margin-bottom: 20px;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border: 0;
    line-height: 30px;
    padding: 0 30px;
    border-radius: 15px;
    background-color: $red;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($red, 5%);
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.support {
  background: url("../images/support-bg.png") center/cover no-repeat;
  text-align: center;
  font-size: 25px;
  padding: 39px 0;
  font-weight: 600;
  .fa {
    margin-right: 10px;
  }
}

.pros {
  padding-left: 60px;
  position: relative;
  img {
    width: 46px;
    position: absolute;
    top: 5px;
    left: 0;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.thumbnail {
  padding: 22px 22px 22px 0;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    border: 5px solid $green;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 72px;
    right: 0;
    bottom: 0;
  }
  img {
    width: 100%;
  }
}

.pricing {
  font-family: $avo;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 20px 10px;
  color: $white;
  background-color: #108518;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    img {
      max-height: 40px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-top: 8px;
  }

  &__desc {
    margin-top: 4px;
    text-align: center;
  }
}

.service {
  box-shadow: 0 4px 16px rgba(#000, 0.3);
  &__body {
    padding: 12px 20px 30px;
  }
  &__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
}
