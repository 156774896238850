.header {
  position: relative;
  z-index: 100;

  &__wrapper {
    width: 100%;
    padding: 16px 0;
    background-color: $white;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background: 0;
    background-color: $green;
    color: $white;
    margin-left: 10px;
  }

  &__logo {
    display: inline-flex;
    img {
      height: 64px;
    }
  }

  &__slogan {
    font-family: $edward-bold;
    font-size: 40px;
    text-align: center;
    color: $red;
    line-height: 1;
    margin-left: 27px;
  }

  @media (max-width: 1199px) {
    margin-top: 40px;

    &__wrapper {
      display: none;
    }

    &__slogan {
      flex-grow: 1;
      margin: 0 12px;
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    &__slogan {
      font-size: 30px;
    }
  }
}

.hotline {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &__icon {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $red;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 16px;
    position: relative;
    z-index: 1;
    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $red;
      animation: phoneRing 3s ease-in-out infinite;
    }
    &::after {
      animation-delay: 1.5s;
    }
  }
  @media (max-width: 991px) {
    position: fixed;
    z-index: 100;
    bottom: 15px;
    left: 15px;
  }
}

@keyframes phoneRing {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  80% {
    width: 180%;
    height: 180%;
    opacity: 0.3;
  }
  100% {
    width: 190%;
    height: 190%;
    opacity: 0;
  }
}

.navigation {
  &__wrapper {
    border-top: 1px solid $green;
  }

  &__btn {
    display: none;
  }

  &__text {
    display: none;
  }

  @media (min-width: 1200px) {
    height: 51px;

    &.is-fixed &__wrapper {
      width: 100%;
      background-color: $white;
      box-shadow: 0 1px 2px rgba(#000, 0.1);
      border: 0;
      position: fixed;
      z-index: 200;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    z-index: 200;
    background-color: $green-darker;
    box-shadow: 0 1px 2px rgba(#000, 0.1);
    border: 0;
    position: fixed;
    top: 0;
    left: 0;

    &__inner {
      display: flex;
      height: 40px;
      align-items: center;
    }

    &__btn {
      display: inline-flex;
      border: 0;
      padding: 0;
      background: 0;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      color: $white;
    }

    &__text {
      display: block;
      font-family: $avo-bold;
      flex-grow: 1;
      text-align: center;
      color: $white;
      font-size: 16px;
      padding: 8px 30px 8px 0;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.search {
  padding: 10px 20px 20px;
  border-bottom: 1px solid $border-color;
  position: relative;

  &__toggle {
    display: none;
  }

  .form-control {
    border-radius: 4px 0 0 4px;
    border-right: 0;

    &:focus {
      border-color: $border-color;
    }
  }

  .input-group-text {
    background-color: #fff;
    border-left: 0;
  }

  @media (min-width: 1200px) {
    order: 1;
    padding: 0;
    border: 0;
    position: relative;
    z-index: 1;
    margin-left: 16px;

    &__toggle {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 50px;
      border: 0;
      background: 0;
      font-size: 16px;
      color: #444;
    }

    &__body {
      display: none;
      width: 300px;
      position: absolute;
      top: 100%;
      right: -2px;
    }
  }
}

.marquee {
  font-size: 16px;
  padding: 5px 0;
  border-top: 1px solid $green;

  &__logo {
    display: inline-flex;
    margin-right: 10px;

    img {
      height: 30px;
    }
  }

  @media (max-width: 1199px) {
    border-top: 0;
  }
}
