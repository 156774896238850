.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background: 0;
  font-style: italic;
  .breadcrumb-item {
    color: #333;
    a {
      color: #333;
    }
    & + .breadcrumb-item::before {
      content: ">";
    }
  }
}
.breadcrumb-2 {
  padding: 14px 0;
  margin-bottom: 20px;
  border-top: 1px solid $border-color;
}
