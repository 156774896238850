.banner-slider {
  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
    img {
      width: 10px;
    }
  }
  .carousel-control-next {
    img {
      width: 10px;
      transform: rotateY(180deg);
    }
  }
  @media (max-width: 767px) {
    .carousel-control-prev,
    .carousel-control-next {
      width: 5%;
      img {
        width: 6px;
      }
    }
  }
}

.partner-slider {
  position: relative;
  padding: 0 30px;
  .owl-prev,
  .owl-next {
    display: flex;
    width: 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    .fa {
      font-size: 22px;
    }
  }
  .owl-next {
    left: auto;
    right: 0;
  }
}

.news-slider,
.home-slider {
  .owl-prev,
  .owl-next {
    display: flex;
    width: 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 0;
    left: -30px;
    .fa {
      font-size: 22px;
    }
  }
  .owl-next {
    left: auto;
    right: -30px;
  }
  @media (max-width: 757px) {
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
    }
  }
}

.card-slider {
  position: relative;

  .owl-prev,
  .owl-next {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $green;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);

    .fa {
      font-size: 35px;
      color: $green;
    }
  }

  .owl-next {
    left: auto;
    right: -40px;
  }

  @media (max-width: 575px) {
    .owl-prev,
    .owl-next {
      width: 30px;
      height: 30px;
      background: rgba(#000, 0.4) !important;

      .fa {
        font-size: 20px;
        color: $white;
      }
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }
  }
}
