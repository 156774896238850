// normalize
body {
  font-size: 14px;
  font-family: $avo, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  // background: url("../images/body-bg.png");
  // color: $white;
  position: relative;
  z-index: 1;

  &.no-scroll {
    overflow: hidden;
  }

  &.cursor {
    &,
    * {
      cursor: pointer;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  a {
    color: inherit;
    transition: 0.3s;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}
a,
input,
button,
textarea,
select {
  &,
  &:focus {
    outline: 0;
  }
}
p:last-child {
  margin-bottom: 0;
}
img {
  max-width: 100%;
}
button,
label {
  cursor: pointer;
}
