@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700");
$open-sans: "Open Sans", sans-serif;

@font-face {
  font-family: "UTMAvo";
  src: url("../fonts/UTMAvo.eot");
  src: url("../fonts/UTMAvo.eot?#iefix") format("embedded-opentype"),
    url("../fonts/UTMAvo.woff") format("woff"),
    url("../fonts/UTMAvo.ttf") format("truetype"),
    url("../fonts/UTMAvo.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "UTMAvo";
  src: url("../fonts/UTMAvoBold.eot");
  src: url("../fonts/UTMAvoBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/UTMAvoBold.woff") format("woff"),
    url("../fonts/UTMAvoBold.ttf") format("truetype"),
    url("../fonts/UTMAvoBold.svg#svgFontName") format("svg");
  font-weight: 700;
}
$avo: "UTMAvo";

@font-face {
  font-family: "UTMAvoBold";
  src: url("../fonts/UTMAvoBold.eot");
  src: url("../fonts/UTMAvoBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/UTMAvoBold.woff") format("woff"),
    url("../fonts/UTMAvoBold.ttf") format("truetype"),
    url("../fonts/UTMAvoBold.svg#svgFontName") format("svg");
}
$avo-bold: "UTMAvoBold";

@font-face {
  font-family: "UTMEdwardianBold";
  src: url("../fonts/UTMEdwardianBold.eot");
  src: url("../fonts/UTMEdwardianBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/UTMEdwardianBold.woff") format("woff"),
    url("../fonts/UTMEdwardianBold.ttf") format("truetype"),
    url("../fonts/UTMEdwardianBold.svg#svgFontName") format("svg");
}
$edward-bold: "UTMEdwardianBold";
