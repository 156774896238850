label {
  font-size: 14px;
}
.form-control {
  font-size: 14px;
  border-radius: 0;
  &:focus {
    box-shadow: unset !important;
  }
}
.form-title {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: $border-color;
    margin-left: 10px;
  }
}
