.footer {
  background: url("../images/footer-bg.png") center/cover no-repeat;
  border-top: 7px solid $green;
  padding-top: 36px;
  color: $white;

  &__title {
    font-size: 16px;
    font-family: $avo-bold;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
}

.f-contact {
  font-family: $open-sans;
  &__title {
    font-size: 14px;
    margin-bottom: 10px;
  }
  &__info {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }
  li {
    padding-left: 24px;
    position: relative;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }

    a,
    a:hover {
      color: inherit;
    }
  }
}

.f-endpage {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $border;
  padding: 12px 0;

  @media (max-width: 991px) {
    flex-direction: column;

    &__copyright {
      margin: 8px;
    }
  }
}

.f-social {
  display: flex;
  margin-top: 15px;

  &__item {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
