.btn {
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 19px;
  text-transform: uppercase;
}
.btn-success {
  background-color: $green;
  &:hover,
  &:active,
  &:focus {
    box-shadow: unset !important;
    background-color: $green;
  }
}
