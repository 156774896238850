// Gray scale
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$gray-f4: #f4f4f4;
$gray-f6: #f6f6f6;

// color
$border: #dee2e6;
$border-color: #dee2e6;
$black: #000;
$white: #fff;
$red: #eb2500;
$green: #0ab717;
$green-darker: #108518;
$navbar-breakpoint: 1200;
$zindex-fixed: 1040;
$primary: $green;
