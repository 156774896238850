.section {
  margin-bottom: 50px;
  &__title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 2px;
  }
  &__desc {
    text-align: center;
    margin-bottom: 25px;
  }
  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.form-block {
  border: 1px solid $border;
  padding: 18px 25px 0;
  margin-bottom: 40px;
  &__title {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.intro {
  text-align: justify;
  &-title {
    font-size: 16px;
    font-family: $avo-bold;
  }
}
